import BoxWrapper from "../../../components/BoxWrapper";
import MoneyReceiveIcon from "../../../components/icons/MoneyReceiveIcon";
import MoneyUpIcon from "../../../components/icons/MoneyUpIcon";
import PercentageIcon from "../../../components/icons/PercentageIcon";
import TicketIcon from "../../../components/icons/TicketIcon";
import BagIcon from "../../../components/icons/BagIcon";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { colors } from "../../../config/theme";
import { useQuery } from "react-query";
import apiService from "../../../service/apiService";
import { useParams } from "react-router-dom";
import { useCookie } from "../../../hooks/useCookie";
import { formatCurrency } from "../../../helpers/general";

interface ServiceSale {
  serviceLable: string;
  totalSales: number;
  totalTickets: number;
  totalNumberOfPersons: number;
  salesPercentage: number;
  ticketsPercentage: number;
}

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const EventStatistics = () => {
  const params = useParams();
  const { token } = useCookie("vToken");

  const { data: statistics } = useQuery(["getEventStatistics"], () => {
    return apiService.MakeGetRequest(
      `events/${params?.eventId}/statistic`,
      token
    );
  });

  const { data: event } = useQuery<Events>(["getEvent"], () => {
    return apiService.MakeGetRequest(`events/${params?.eventId}`);
  });

  return (
    <section>
      <h5 className="fs-4 fw-bold mb-2">Statistiques</h5>
      <BoxWrapper>
        <div className="container my-3 py-4">
          <h2 className="fw-medium mb-3 text-center">{event?.name}</h2>
          <div className="row text-center mb-4">
            <div className="col-md-12">
              <div className="shadow p-3 mb-4 rounded-5 d-flex justify-content-center align-items-center gap-3">
                <div className="rounded-circle bg-success p-3 bg-opacity-10">
                  <MoneyReceiveIcon
                    width="80"
                    height="80"
                    className="text-success text-center"
                  />
                </div>
                <div>
                  <h5>Total des ventes</h5>
                  <h4 className="fw-bold">
                    {formatCurrency(statistics?.totalSales ?? 0)} MAD
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-6 col-md-3 px-2">
              <div className="shadow-sm mb-2 py-2 bg-primary text-white rounded-5 d-flex flex-column flex-md-row  align-items-center gap-2 gap-lg-4">
                <div className="rounded-circle bg-white ms-2 p-2">
                  <BagIcon className="w-5 h-5 text-primary" />
                </div>
                <div className="text-center text-md-start py-2 px-1 d-flex flex-column ">
                  <span className="text-sm text-md-lg">Nombre de ventes</span>
                  <span className="fw-semibold fs-6">
                    {statistics?.totalTickets ?? 0}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-3 px-2">
              <div className="shadow-sm mb-2 py-2 bg-danger text-white rounded-5 d-flex flex-column flex-md-row  align-items-center gap-2 gap-lg-4">
                <div className="rounded-circle bg-white ms-2 p-2">
                  <TicketIcon className="w-5 h-5 text-danger" />
                </div>
                <div className="text-center text-md-start py-2 px-1 d-flex flex-column ">
                  <span className="text-sm text-md-lg">Nombre de tickets</span>
                  <span className="fw-semibold fs-6">
                    {statistics?.totalNumberOfPersons ?? 0}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-3 px-2">
              <div className="shadow-sm mb-2 py-2 bg-success text-white rounded-5 d-flex flex-column flex-md-row  align-items-center gap-2 gap-lg-4">
                <div className="rounded-circle bg-white ms-2 p-2">
                  <PercentageIcon className="w-5 h-5 text-success" />
                </div>
                <div className="text-center text-md-start py-2 px-1 d-flex flex-column ">
                  <span className="text-sm text-md-lg">Commission</span>
                  <span className="fw-semibold fs-6">
                    {formatCurrency(statistics?.commission ?? 0)} MAD
                  </span>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-3 px-2">
              <div className="shadow-sm mb-2 py-2 bg-black text-white rounded-5 d-flex flex-column flex-md-row  align-items-center gap-2 gap-lg-4">
                <div className="rounded-circle bg-white ms-2 p-2">
                  <MoneyUpIcon className="w-5 h-5 text-black" />
                </div>
                <div className="text-center text-md-start py-2 px-1 d-flex flex-column ">
                  <span className="text-sm text-md-lg">Nombre des gains</span>
                  <span className="fw-semibold fs-6">
                    {formatCurrency(statistics?.totalOfGains ?? 0)} MAD
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="row text-center mt-5 d-flex justify-content-center">
            <div className="col-md-6 col-lg-4 col-11">
              <h5>Pourcentage de vente de chaque service</h5>
              <div className="card border-0">
                <div className="card-body">
                  {statistics?.totalSales &&
                  statistics?.servicesSales.length > 0 ? (
                    <Pie
                      className="w-100 h-100"
                      data={{
                        labels: statistics?.servicesSales
                          .filter(
                            (s: ServiceSale) =>
                              s.salesPercentage && s.totalSales
                          )
                          .map((s: ServiceSale) => s.serviceLable),
                        datasets: [
                          {
                            data: statistics?.servicesSales
                              .filter(
                                (s: ServiceSale) =>
                                  s.salesPercentage && s.totalSales
                              )
                              .map((s: ServiceSale) => s.salesPercentage),
                            borderColor: ["transparent"],
                            backgroundColor: [
                              colors.primary,
                              colors.secondary,
                              colors.yellow,
                              colors.green,
                              colors.red
                            ]
                          }
                        ]
                      }}
                      options={{
                        plugins: {
                          datalabels: {
                            display: true,
                            color: "white",
                            font: {
                              family: "Poppins",
                              size: 14
                            },
                            labels: {
                              name: {
                                align: "center",
                                textAlign: "center",
                                font: { size: 20, weight: "bold" },
                                formatter: (value: number, context: any) => {
                                  const percentage =
                                    statistics?.servicesSales[context.dataIndex]
                                      .salesPercentage;
                                  return `${percentage ?? 0} %`;
                                }
                              },
                              value: {
                                align: "bottom",
                                textAlign: "center",
                                formatter: (value: number, context: any) => {
                                  const nbTickets =
                                    statistics?.servicesSales[context.dataIndex]
                                      .totalNumberOfPersons;
                                  return `${nbTickets ?? 0} ${
                                    nbTickets > 1 ? "tickets" : "ticket"
                                  }`;
                                },
                                padding: 6
                              }
                            }
                          },
                          legend: {
                            labels: {
                              color: "#000000",
                              usePointStyle: true,
                              pointStyle: "circle",
                              font: { family: "Poppins", size: 14 }
                            },
                            position: "bottom"
                          },
                          tooltip: {
                            enabled: true,
                            callbacks: {
                              label: (context: any) => {
                                const sales =
                                  statistics?.servicesSales[context.dataIndex]
                                    .totalSales;
                                return `${sales ?? 0} MAD`;
                              }
                            }
                          }
                        },
                        responsive: true
                      }}
                    />
                  ) : (
                    <>
                      <p className="mt-2 text-muted">
                        Il n'y a pas de données à afficher
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </BoxWrapper>
    </section>
  );
};

export default EventStatistics;
